import { render, staticRenderFns } from "./AssessmentView.vue?vue&type=template&id=9519a91e&scoped=true&"
import script from "./AssessmentView.vue?vue&type=script&lang=js&"
export * from "./AssessmentView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9519a91e",
  null
  
)

export default component.exports