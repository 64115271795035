<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active" v-if="apiLoaded">
      <assessment-component
          :application="application" :assessment="assessment"
          only-view="true"
          @goBack="$router.go(-1)"></assessment-component>
    </transition>
  </div>
</template>

<script>
import AssessmentComponent from "@/components/AssessmentComponent";
import api from "@/modules/api";

export default {
  name: "AssessmentView",
  components: {AssessmentComponent},
  data() {
    return {
      assessment: null,
      application: null,
      apiLoaded: false,
      apiError: false
    }
  },
  methods: {
    async loadAssessment(id) {
      let req = await api.get("/assessments/" + id)
      if (req.ok) {
        console.log(req.payload)
        this.assessment = req.payload
      } else alert('Ошибка при обращении к серверу!')
    },
    async loadApplication(id) {
      let req = await api.get("/applications/" + id)
      if (req.ok) {
        console.log(req.payload)
        this.application = req.payload
      } else alert('Ошибка при обращении к серверу!')
    }
  },
  async beforeMount() {
    let id = this.$route.params.id
    await this.loadAssessment(id)
    await this.loadApplication(this.assessment.applicationId)
    this.apiLoaded = true
  }
}
</script>

<style scoped>

</style>
